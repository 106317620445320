<template>
  <div class="opening-times-container mb-6">
    <div class="description-container">
      <p class="mb-6">{{$t('services.serviceDetail.openingTimes.description')}}</p>
    </div>
    <div class="controls-container">
      <div class="days">
        <div class="service-variety" v-for="(serviceVarietyOpening, serviceVarietyIndex) in value" :key="serviceVarietyOpening.serviceVariety">
          <h3 class="mb-2">{{$t(`services.serviceDetail.openingTimes.serviceVariety${serviceVarietyOpening.serviceVariety}`)}}</h3>
          <div>
            <v-row v-if="!editEnabled" class="info-line mb-2">
              <v-col v-if="serviceVarietyOpening.useCustomTimes" class="pl-3">
                <v-icon class="icon-blue">$vuetify.icons.partouInfo</v-icon>
                <span class="pl-2 info-line-text">{{$t('services.serviceDetail.openingTimes.descriptionAlteredTimes')}}</span>
              </v-col>
              <v-col v-else></v-col>
            </v-row>
            <v-row v-else class="overwrite-times-checkbox mb-4">
              <v-col cols="auto">
                <PartouCheckBox
                  :name="serviceVarietyOpening.serviceVariety"
                  v-model="serviceVarietyOpening.useCustomTimes"
                  :disabled="!canManageServiceOpeningTimes"
                />
              </v-col>
              <v-col class="pl-1">
                <span @click="() => handleCheckboxLabelClick(serviceVarietyIndex)">{{ $t('services.serviceDetail.openingTimes.overrideOpeningTimes') }}</span>
              </v-col>
            </v-row>
          </div>
          <div class="opening-times ml-4">
            <v-row v-for="(day, index) in days" :key="index" align="center" class="row mt-2">
              <template v-if="editEnabled && serviceVarietyOpening.useCustomTimes">
                <v-col class="col-day-editable">
                  <v-switch
                    inset
                    v-model="customDayOpenings"
                    :disabled="!canManageServiceOpeningTimes"
                    :value="`${serviceVarietyOpening.serviceVariety}_${day}`"
                    :label="getDayLabel(day)"
                    @change="() => toggleCustomDayOpen(serviceVarietyIndex, day)" />
                </v-col>
                <template v-if="isOpen(serviceVarietyIndex, day)">
                  <v-col class="col-time-editable">
                    <PartouTimePicker
                      :initialValue="getFromTime(serviceVarietyIndex, day)"
                      :max="getUntilTime(serviceVarietyIndex, day)"
                      :disabled="!editEnabled || !serviceVarietyOpening.useCustomTimes || !canManageServiceOpeningTimes"
                      :label="$t('services.serviceDetail.openingTimes.timeFrom')"
                      @onTimeChanged="(value) => setFromTime(value, serviceVarietyIndex, day)">
                    </PartouTimePicker>
                  </v-col>
                  <v-col><span>-</span></v-col>
                  <v-col class="col-time-editable">
                    <PartouTimePicker
                      :initialValue="getUntilTime(serviceVarietyIndex, day)"
                      :min="getFromTime(serviceVarietyIndex, day)"
                      :disabled="!editEnabled || !serviceVarietyOpening.useCustomTimes || !canManageServiceOpeningTimes"
                      :label="$t('services.serviceDetail.openingTimes.timeUntil')"
                      @onTimeChanged="(value) => setUntilTime(value, serviceVarietyIndex, day)">
                    </PartouTimePicker>
                  </v-col>
                </template>
                <template v-else>
                  <v-col class="pl-6"><span>{{$t('services.serviceDetail.openingTimes.closed')}}</span></v-col>
                </template>
              </template>
              <template v-else>
                <v-col class="col-day-not-editable"><span>{{$t(`days.${day}`)}}</span></v-col>
                <template v-if="isOpen(serviceVarietyIndex, day)">
                  <v-col class="col-time-not-editable"><span>{{getFromTime(serviceVarietyIndex, day)}}</span></v-col>
                  <v-col><span>-</span></v-col>
                  <v-col class="col-time-not-editable"><span>{{getUntilTime(serviceVarietyIndex, day)}}</span></v-col>
                </template>
                <template v-else>
                  <v-col class="pl-6"><span>{{$t('services.serviceDetail.openingTimes.closed')}}</span></v-col>
                </template>
              </template>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import PartouTimePicker from '@/components/PartouComponents/PartouTimePicker.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import { numberInputDirective } from '@/utils/directives'
import { OpeningTimes, ServiceVarietyOpeningTimes } from './ServiceVarietyOpeningTimes'
import '@/utils/listUtils'
import { ServiceVarietyOpening } from '@/models'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'

enum OpeningVariant {
  STANDARD, // Use standard group opening times.
  CUSTOM, // Use custom opening times.
  FROM_OBJECT // Depending on useCustomTimes setting in object.
}

@Component({
  directives: { numberInputDirective },
  components: { PartouTimePicker, PartouCheckBox }
})
export default class ServiceVarietyOpeningSettings extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  editEnabled! : boolean

  @Prop({ required: true, default: () => { return [] } })
  value! : ServiceVarietyOpeningTimes[]

  customDayOpenings: string[] = []

  get days () : string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  get canManageServiceOpeningTimes (): boolean {
    return this.authService.hasPermission([Permission.ManageServiceOpeningTimes])
  }

  @Watch('value', { deep: true, immediate: true })
  updateSwitchModel () {
    this.customDayOpenings = []

    this.value.forEach((serviceVarietyOpeningTimes, i) => {
      this.days.forEach((day) => {
        if (this.isOpen(i, day, OpeningVariant.CUSTOM)) {
          this.customDayOpenings.push(`${serviceVarietyOpeningTimes.serviceVariety}_${day}`)
        }
      })
    })
  }

  getDayLabel (day: string): string {
    return this.$t(`days.${day}`).toString()
  }

  getOpeningHours (serviceVarietyIndex: number, day: string, variant: OpeningVariant = OpeningVariant.FROM_OBJECT): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const serviceVarietyOpeningTimes = this.value[serviceVarietyIndex]

    let openingHours
    if (variant === OpeningVariant.STANDARD) {
      openingHours = serviceVarietyOpeningTimes.standard
    } else if (variant === OpeningVariant.CUSTOM) {
      openingHours = serviceVarietyOpeningTimes.custom
    } else {
      openingHours = serviceVarietyOpeningTimes.useCustomTimes ? serviceVarietyOpeningTimes.custom : serviceVarietyOpeningTimes.standard
    }

    return openingHours[day as keyof ServiceVarietyOpening]
  }

  isOpen (serviceVarietyIndex: number, day: string, variant: OpeningVariant = OpeningVariant.FROM_OBJECT) : boolean {
    const openingHours = this.getOpeningHours(serviceVarietyIndex, day, variant)
    return !!(openingHours && openingHours[0] && openingHours[0].From !== null && openingHours[0].Until != null)
  }

  getFromTime (serviceVarietyIndex: number, day: string, variant: OpeningVariant = OpeningVariant.FROM_OBJECT) {
    const openingHours = this.getOpeningHours(serviceVarietyIndex, day, variant)
    return openingHours && openingHours[0] && openingHours[0].From
  }

  getUntilTime (serviceVarietyIndex: number, day: string, variant: OpeningVariant = OpeningVariant.FROM_OBJECT) {
    const openingHours = this.getOpeningHours(serviceVarietyIndex, day, variant)
    return openingHours && openingHours[0] && openingHours[0].Until
  }

  setFromTime (value: string, serviceVarietyIndex: number, day: string) : void {
    const openingTimes = this.getOpeningHours(serviceVarietyIndex, day, OpeningVariant.CUSTOM)
    openingTimes[0].From = value
  }

  setUntilTime (value: string, serviceVarietyIndex: number, day: string) : void {
    const openingTimes = this.getOpeningHours(serviceVarietyIndex, day, OpeningVariant.CUSTOM)
    openingTimes[0].Until = value
  }

  toggleCustomDayOpen (serviceVarietyIndex: number, day: string): void {
    const serviceVarietyOpeningTimes = this.value[serviceVarietyIndex]
    if (!serviceVarietyOpeningTimes.useCustomTimes) {
      return
    }

    const openingTimes: OpeningTimes = {
      From: null,
      Until: null
    }

    // If the location has no custom opening hours then set opening hours based on standard opening hours or assign
    // default opening hours if the location is closed on the given day.
    if (!this.isOpen(serviceVarietyIndex, day, OpeningVariant.CUSTOM)) {
      if (this.isOpen(serviceVarietyIndex, day, OpeningVariant.STANDARD)) {
        openingTimes.From = this.value[serviceVarietyIndex].standard[day as keyof ServiceVarietyOpening][0].From
        openingTimes.Until = this.value[serviceVarietyIndex].standard[day as keyof ServiceVarietyOpening][0].Until
      } else {
        openingTimes.From = '07:30'
        openingTimes.Until = '18:30'
      }
    }

    Vue.set(this.value[serviceVarietyIndex].custom, day, [openingTimes])
  }

  handleCheckboxLabelClick (serviceVarietyIndex: number) {
    const selectedServiceVariety = this.value[serviceVarietyIndex]
    Vue.set(selectedServiceVariety, 'useCustomTimes', !selectedServiceVariety.useCustomTimes)
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';
  @import '@/styles/switch.scss';

  h3 {
    color: $partou-primary-black-ninety;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
  }

  .overwrite-times-checkbox {
    .col {
      padding-top: 0;
      padding-bottom: 0;
      color: $partou-primary-black-ninety;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  .opening-times-container {
    width: 100%;

    .description-container {
      width: 50%;
    }

    .controls-container {
      width: 50%;
      display: flex;
    }

    .days{
      display: flex;
      flex-direction: row;
      justify-content: center;

      .service-variety {
        width: 385px;
        min-width: 385px;
        max-width: 385px;

        .opening-times {
          display: flex;
          gap: 2px;
          flex-direction: column;

          .row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 16px;

            .col {
              padding: 3px 0 0 0;
              max-width: fit-content;
              height: 40px;
              max-height: 40px;
              align-content: center;
              color: $partou-primary-black-seventy;
              line-height: 115%;
            }

            .col-day-editable {
              padding-top: 5px;
            }

            .col-time-editable {
              padding-top: 6px;
            }

            .col-day-not-editable {
              width: 160px;
              min-width: 160px;
              max-width: 160px;
              padding-left: 72px !important;
            }

            .col-time-not-editable {
              width: 86px;
              min-width: 86px;
              max-width: 86px;
              text-align: center;
            }
          }
        }
      }

      .service-variety:nth-child(1) {
        margin-right: 24px;
      }

      .service-variety:nth-child(2) {
        padding-left: 24px;
        border-left: solid 1px $partou-primary-salmon-fourty;
      }
    }
  }

  .v-input--switch {
    width: 160px;
  }

  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .info-line::v-deep {
    color: $partou-primary-black-seventy;
    height: 16px;

    .col {
      padding: 0;
    }

    .info-line-text {
      line-height: 16px;
      vertical-align: middle;
    }
  }

  .icon-blue {
    fill: $partou-primary-blue;
  }
</style>
